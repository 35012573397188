import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Spinner,
  Modal,
  Form
} from "react-bootstrap";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/fr"; // Ajouter le locale français pour Moment.js
import api from "../../utils/api";
import {
  onTheWaySt,
  arrivedToPickSt,
  startTheRideSt,
  stopTheRideSt
} from "./transferStFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlane,
  faTrain,
  faClosedCaptioning
} from "@fortawesome/free-solid-svg-icons";
import { GeoLocationContext } from "../Global/GeoLocationContext";


function Transfer() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [transfer, setTransfer] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [problems, setProblems] = useState("");
  const [state, setState] = useState("");
  const [status, setStatus] = useState(null);
  const [isFlight, setIsFlight] = useState(false);
  const [isTrain, setIsTrain] = useState(false);
  const [showProblemModal, setShowProblemModal] = useState(false);
  
  const { locationPermission, requestLocationPermission } = useContext(GeoLocationContext);

  const handleCloseProblemModal = useCallback(
    () => setShowProblemModal(false),
    []
  );
  const handleShowProblemModal = useCallback(
    () => setShowProblemModal(true),
    []
  );

      // Vérifie et demande la permission de géolocalisation si nécessaire
      useEffect(() => {
        if (!locationPermission) {
          requestLocationPermission();
        }
      }, [locationPermission, requestLocationPermission]);
    
      // Redirection si la permission est refusée
      useEffect(() => {
        if (locationPermission === "denied") {
          navigate("/error-loc", { state: { from: location.pathname } });
        }
      }, [locationPermission, navigate, location.pathname]);


  const saveProblems = useCallback(() => {
    if (!transfer?.IdResa) return;

    api
      .post(`/transfers/${transfer.IdResa}/problems`, {
        comments: problems,
        state: state,
      })
      .then(() => {
        if (state) {
          setStatus("DROPPED_OFF");
        }
        setShowProblemModal(false);
      })
      .catch((error) => {
        console.error("Erreur lors de l'enregistrement des problèmes :", error);
      });
  }, [transfer.IdResa, problems, state]);

  const getTransferSt = useCallback(async (uuid) => {
    try {
      const response = await api.get(`/transfers-st/${uuid}`);
      const data = response.data;
      setTransfer(data);
      setStatus(data.status);
      setIsFlight(
        data.AdresseDep.toLowerCase().includes("airport") ||
        data.AdresseDep.toLowerCase().includes("aéroport") ||
        data.NVol !== ""
      );
      setIsTrain(
        data.AdresseDep.toLowerCase().includes("gare") ||
        data.AdresseDep.toLowerCase().includes("station")
      );
      setProblems(data.comments || "");
      setState(data.Etat || "");
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setTransfer(null); // Réinitialiser les données si 404
        navigate("/error", { state: { message: "Transfert introuvable." } }); // Rediriger
      } else {
        console.error("Erreur lors de la récupération du transfert :", error);
        alert("Une erreur est survenue. Veuillez réessayer.");
        setTransfer(null); // Réinitialiser en cas d'autres erreurs
      }
    } finally {
      setLoading(false);
    }
  }, [navigate]);
  

  useEffect(() => {
    getTransferSt(params.uuid);
  }, [getTransferSt, params.uuid]);

  console.log(transfer);
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status" variant="success">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container fluid style={{ paddingBottom: "10px" }}>
          
          <Row className="my-3 align-items-center">
  <Col xs="auto">
    <img
      style={{ height: "70px" }} // Augmentation de la hauteur pour une meilleure visibilité
      src="https://www.odysseetransfer.com/wp-content/themes/Odyssee_transfer/images/logo_white_250.png"
      alt="Odyssee Transfer Logo"
    />
  </Col>
  <Col className="text-center">
    <h3 className="fw-bold fs-5 text-light mb-0">Ride details</h3>
  </Col>
</Row>


      <RideDetails transfer={transfer} />
      <Additional isFlight={isFlight} isTrain={isTrain} transfer={transfer} />
      <Comments transfer={transfer} />

      <Row className="p-2" style={{ bottom: "80px" }}>
              <a
                href={`/sign-name?lastname=${transfer.NomResa}&firstname=${transfer.PrenomResa}`}
                className="btn btn-secondary btn-lg"
                role="button"
              >
                <FontAwesomeIcon icon={faClosedCaptioning} /> Sign Name
              </a>
      </Row>

      <ActionButtons
        status={status}
        transferDriverUiid={params.uuid}
        paymentMethod={transfer.Paiement}
        setStatus={setStatus}
        timestamps={{
          on_the_way_time: transfer.on_the_way_time,
          arrived_to_pick_time: transfer.arrived_to_pick_time,
          picked_up_time: transfer.picked_up_time,
          dropped_off_time: transfer.dropped_off_time,
        }}
      />

      <Row className="p-2">
        <Button variant="danger" size="lg" onClick={handleShowProblemModal}>
          Signal a problem
        </Button>
      </Row>

      <ProblemModal
        show={showProblemModal}
        handleClose={handleCloseProblemModal}
        state={state}
        problems={problems}
        setState={setState}
        setProblems={setProblems}
        saveProblems={saveProblems}
      />

    </Container>
  );
}

const RideDetails = ({ transfer }) => {

  return (
    <>
      <Row className="p-2">
        <Card className="shadow bg-white rounded">
          <Card.Header>Date</Card.Header>
          <Card.Body>
            <Card.Text className="fw-bold">
              <Moment format="LL">{transfer.DateResa}</Moment> -{" "}
              {transfer.HeureResa}
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>

      <Row className="p-2">
        <Card className="shadow bg-white rounded">
          <Card.Header>Driver</Card.Header>
          <Card.Body>
            <Card.Text className="fw-bold">
              <span>Name : {transfer.Nom}</span><br/>
              <span>Vehicle : {transfer.Modele} - {transfer.Couleur} - {transfer.Plaque}</span>
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>

      <Row className="p-2">
        <Card className="shadow bg-white rounded">
          <Card.Header>Pick-up location</Card.Header>
          <Card.Body>
            <Card.Text className="fw-bold">
              <a
                href={`https://www.waze.com/ul?ll=${transfer.LatDep}%2C${transfer.LngDep}&navigate=yes&zoom=17s`}
                target="_blank"
                rel="noreferrer"
              >
                {transfer.AdresseDep}
              </a>
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>

      {transfer.AdresseEtape1 !== "" && transfer.AdresseEtape1 !== null && (
        <Row className="p-2">
          <Card className="shadow bg-white rounded">
            <Card.Header>Step 1</Card.Header>
            <Card.Body>
              <Card.Text className="fw-bold">
                <a
                  href={`https://www.waze.com/ul?q=${encodeURIComponent(
                    transfer.AdresseEtape1
                  )}&navigate=yes&zoom=17s`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {transfer.AdresseEtape1}
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      )}

      {transfer.AdresseEtape2 !== "" && transfer.AdresseEtape2 !== null && (
        <Row className="p-2">
          <Card className="shadow bg-white rounded">
            <Card.Header>Step 2</Card.Header>
            <Card.Body>
              <Card.Text className="fw-bold">
                <a
                  href={`https://www.waze.com/ul?q=${encodeURIComponent(
                    transfer.AdresseEtape2
                  )}&navigate=yes&zoom=17s`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {transfer.AdresseEtape2}
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      )}

      <Row className="p-2">
        <Card className="shadow bg-white rounded">
          <Card.Header>Drop-off location</Card.Header>
          <Card.Body>
            <Card.Text className="fw-bold">
              <a
                href={`https://www.waze.com/ul?ll=${transfer.LatArr}%2C${transfer.LngArr}&navigate=yes&zoom=17s`}
                target="_blank"
                rel="noreferrer"
              >
                {transfer.AdresseArr}
              </a>
            </Card.Text>

            {/* <EstimationTemps
              latOrigine={transfer.LatDep}
              lngOrigine={transfer.LngDep}
              latDestination={transfer.LatArr}
              lngDestination={transfer.LngArr}
            /> */}
          </Card.Body>
        </Card>
      </Row>
      {/* <Map
        origin={{ lat: transfer.LatDep, lng: transfer.LngDep }}
        destination={{ lat: transfer.LatArr, lng: transfer.LngArr }}
        step1={transfer.AdresseEtape1}
        step2={transfer.AdresseEtape2}
      /> */}

      <Row className="p-2">
        <Card className="shadow bg-white rounded">
          <Card.Header>Passenger informations</Card.Header>
          <Card.Body>
            <Container>
              <Row>
                <Col>
                  <p>Passenger name</p>
                  <p className="fw-bold">
                    <a
                      href={`/sign-name?lastname=${transfer.NomResa}&firstname=${transfer.PrenomResa}`}
                    >
                      {transfer.NomResa} {transfer.PrenomResa}
                    </a>
                  </p>
                </Col>
                <Col>
                  <p>Passenger contact</p>
                  <Callto phone={transfer.NumTel}>{transfer.NumTel}</Callto>
                </Col>
                <Col>
                  <p>Number of passengers</p>
                  <p className="fw-bold">{transfer.Pax}</p>
                </Col>
                {(localStorage.getItem("role") === "Administrateur" ||
                  localStorage.getItem("role") === "Super Administrateur") && (
                    <Col>
                      <p>Vehicle type</p>
                      <p className="fw-bold">{transfer.Type}</p>
                    </Col>
                  )}
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};


const Additional = ({ isFlight, isTrain, transfer }) => {
  if (isTrain) {
    return (
      <Row className="p-2">
        <Card className="shadow bg-white rounded">
          <Card.Header>
            Additional informations <FontAwesomeIcon icon={faTrain} />
          </Card.Header>
          <Card.Body>
            <Container>
              <Row>
                <Col>
                  <p>Train arrival time</p>
                  <p className="fw-bold">{transfer.HeureResa}</p>
                </Col>
                <Col>
                  <p>Train number</p>
                  <p className="fw-bold">{transfer.NTrain}</p>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </Row>
    );
  }
  if (isFlight) {
    return (
      <Row className="p-2">
        <Card className="shadow bg-white rounded">
          <Card.Header>
            Additional informations <FontAwesomeIcon icon={faPlane} />
          </Card.Header>
          <Card.Body>
            <Container>
              <Row>
                <Col lg xs="6">
                  <p>Flight arrival time</p>
                  <p className="fw-bold">{transfer.HeureResa}</p>
                </Col>
                <Col lg xs="6">
                  <p>Flight number</p>
                  <p className="fw-bold">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.google.fr/search?q=${transfer.NVol}`}
                    >
                      {transfer.NVol}
                    </a>
                  </p>
                </Col>
                <Col lg xs="6">
                  <p>Flight terminal</p>
                  <p className="fw-bold">{transfer.Terminal}</p>
                </Col>
                <Col lg xs="6">
                  <p>Luggage</p>
                  <p className="fw-bold">{transfer.Bagages}</p>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </Row>
    );
  }
  return null;
};

const Comments = ({ transfer }) => {
  if (transfer.Commentaire) {
    return (
      <Row className="p-2">
        <Card className="shadow bg-white rounded">
          <Card.Header>Comments</Card.Header>
          <Card.Body>
            <Card.Text className="fw-bold">{transfer.Commentaire}</Card.Text>
          </Card.Body>
        </Card>
      </Row>
    );
  }
  return null;
};


const ActionButtons = ({
  status,
  transferDriverUiid,
  setStatus,
}) => {
  // const [location, setLocation] = useState(null);
  const [isFetchingLocation, setIsFetchingLocation] = useState(false); // Spinner pour localisation

  const fetchLocationAndExecute = useCallback((action) => {
    setIsFetchingLocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const loc = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setIsFetchingLocation(false);
          action(loc); // Exécuter l'action avec la localisation mise à jour
        },
        (error) => {
          console.error("Erreur de géolocalisation :", error);
          alert("Erreur lors de la récupération de la localisation.");
          setIsFetchingLocation(false);
        }
      );
    } else {
      alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
      setIsFetchingLocation(false);
    }
  }, []);
  // Modification des fonctions d'état pour inclure la localisation
  const handleOnTheWay = () =>
    fetchLocationAndExecute((loc) => onTheWaySt(transferDriverUiid, setStatus, loc));
  const handleArrivedToPick = () =>
    fetchLocationAndExecute((loc) => arrivedToPickSt(transferDriverUiid, setStatus, loc));
  const handleStartTheRide = () =>
    fetchLocationAndExecute((loc) => startTheRideSt(transferDriverUiid, setStatus, loc));
  const handleStopTheRide = () =>
    fetchLocationAndExecute((loc) => stopTheRideSt(transferDriverUiid, setStatus, loc));

  switch (status) {
    case "NOT_STARTED":
      return (
        <>
          {/* Bouton avec Spinner pour indiquer l’attente de la localisation */}
          {isFetchingLocation ? (
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Row className="p-2 sticky-bottom" style={{ top: "80px" }}>
              <Button
                variant="primary"
                size="lg"
                onClick={handleOnTheWay}
                disabled={isFetchingLocation}
              >
                On the way
              </Button>
            </Row>
          )}
        </>
      );
    case "ON_THE_WAY":
      return (
        <Row className="p-2 sticky-bottom" style={{ top: "80px" }}>
          <Button
            variant="info"
            size="lg"
            onClick={handleArrivedToPick}
            disabled={isFetchingLocation}
          >
            Arrived to pick up
          </Button>
        </Row>
      );
    case "ARRIVED":
      return (
        <Row className="p-2 sticky-bottom" style={{ top: "80px" }}>
          <Button
            variant="success"
            size="lg"
            onClick={handleStartTheRide}
            disabled={isFetchingLocation}
          >
            Client picked up
          </Button>
        </Row>
      );
    case "PICKED_UP":
      return (
        <Row className="p-2 sticky-bottom" style={{ top: "80px" }}>
          <Button
            variant="warning"
            size="lg"
            onClick={handleStopTheRide}
            disabled={isFetchingLocation}
          >
            Client dropped off
          </Button>
        </Row>
      );
    case "DROPPED_OFF":
      return (
        <Row className="p-2 row">
          <Button variant="success" size="lg" disabled>
            Finished
          </Button>
        </Row>
      );
    default:
      return null;
  }
};

const Callto = ({ phone, children }) => (
  <p className="fw-bold">
    <a className="text-decoration-none" href={`tel:${phone}`}>
      {children}
    </a>
    <a
      className="text-decoration-none ms-2"
      href={`whatsapp://send?phone=${phone}`}
      target="_blank"
      rel="noreferrer"
    >
      <svg
        width="20"
        height="20"
        fill="#38761D"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
      </svg>
    </a>
  </p>
);

const ProblemModal = ({
  show,
  handleClose,
  state,
  problems,
  setState,
  setProblems,
  saveProblems,
}) => (
  <Modal
    show={show}
    onHide={handleClose}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>Signal the problem</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group className="mb-3" controlId="etat">
          <Form.Label>Etat</Form.Label>
          <Form.Select
            aria-label="Etat select"
            value={state}
            onChange={(e) => setState(e.target.value)}
          >
            <option value="">Choisir une option</option>
            <option value="No Show">No Show</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="problems">
          <Form.Label>Comments</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={problems}
            onChange={(e) => setProblems(e.target.value)}
          />
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" onClick={saveProblems}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
);

export default Transfer;
