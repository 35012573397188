import React, { createContext, useState, useCallback } from "react";

export const GeoLocationContext = createContext();

export const GeoLocationProvider = ({ children }) => {
  const [locationPermission, setLocationPermission] = useState(null);

  const requestLocationPermission = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        () => setLocationPermission("granted"),
        (error) => {
          console.error("Erreur de géolocalisation:", error);
          setLocationPermission("denied");
        }
      );
    } else {
      alert("La géolocalisation n'est pas supportée par ce navigateur.");
      setLocationPermission("denied");
    }
  }, []);

  return (
    <GeoLocationContext.Provider
      value={{ locationPermission, requestLocationPermission }}
    >
      {children}
    </GeoLocationContext.Provider>
  );
};
