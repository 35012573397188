import React from "react";
import { useLocation  } from "react-router-dom";

const ErrorPage = () => {
    const location = useLocation();
    
    const handleRetry = () => {
        const from = location.state?.from || "/";
        window.location.href = from; // Recharge complètement la page avec l'URL d'origine
    };

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center text-white text-center"
      style={{
        height: "100vh",
        backgroundColor: "#1a1a1a", // Ajout d'un fond sombre pour le contraste
        padding: "20px",
      }}
    >
      <h2 className="mb-3">Localisation requise</h2>
      <p className="mb-3">
        Vous devez activer la localisation pour accéder à cette page.
      </p>
      <button
        className="btn btn-primary mb-4"
        onClick={handleRetry}
        style={{ padding: "10px 20px", fontSize: "16px" }}
      >
        Réessayer
      </button>
      <p className="mb-3">Pour activer la localisation :</p>
      <ul className="text-start" style={{ maxWidth: "400px" }}>
        <li>
          <strong>Sur Chrome :</strong> Allez dans <em>Paramètres</em> &gt;
          <em>Confidentialité et sécurité</em> &gt; <em>Paramètres du site</em>{" "}
          &gt; <em>Localisation</em>.
        </li>
        <li>
          <strong>Sur Firefox :</strong> Allez dans <em>Paramètres</em> &gt;{" "}
          <em>Vie privée et sécurité</em> &gt; <em>Autorisations</em> &gt;{" "}
          <em>Localisation</em>.
        </li>
        <li>
          <strong>Sur Safari :</strong> Allez dans <em>Préférences</em> &gt;{" "}
          <em>Sites Web</em> &gt; <em>Localisation</em>.
        </li>
      </ul>
      <p className="mt-4">
        Si le problème persiste, essayez de redémarrer votre navigateur ou de
        vérifier les paramètres de votre appareil.
      </p>
    </div>
  );
};

export default ErrorPage;
