import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import Login from "./components/Login/Login";
import Transfers from "./components/Transfers/Transfers";
import Transfer from "./components/Transfers/Transfer";
import TransferSt from "./components/Transfers/TransferSt";
import Menu from "./components/Global/Menu";
import BottomMenu from "./components/Global/BottomMenu";
import SignName from "./components/Transfers/SignName";
import Account from "./components/Account/Account";
import { SwipeProvider } from "./components/Transfers/SwipeContext";
import ErrorPage from "./components/Global/ErrorPage";
import NotFoundPage from "./components/Global/NotFoundPage";
import { GeoLocationProvider } from "./components/Global/GeoLocationContext";


const App = () => {

  // Fonction utilitaire pour vérifier l'authentification
  const isAuthenticated = () => localStorage.getItem("authenticated");
    return (
      <GeoLocationProvider>

      <Router>
        <Menu />
        <Routes>
          <Route
            path="transfers"
            element={
              <PrivateRoute auth={isAuthenticated()}>
                <SwipeProvider>
                  <Transfers />
                </SwipeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="transfers/:id"
            element={
              <PrivateRoute auth={isAuthenticated()}>
                <Transfer />
              </PrivateRoute>
            }
          />
          <Route
            path="/sign-name"
            element={
                <SignName />
            }
          />
          <Route
            path="/account"
            element={
              <PrivateRoute auth={isAuthenticated()}>
                <Account />
              </PrivateRoute>
            }
          />
          <Route
            path="transfers-st/:uuid"
            element={<TransferSt />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate replace to="/transfers" />} />
          <Route path="/error" element={<NotFoundPage />} />
          <Route path="/error-loc" element={<ErrorPage />} />

        </Routes>
        <BottomMenu auth={isAuthenticated()} />
      </Router>
      </GeoLocationProvider>
    );
  }

export default App;
