// transferStFunctions.js
import api from "../../utils/api";

export const onTheWaySt = async (transferDriverUiid, setStatus, location) => {
  try {

    await api.post(`/transfers-st/${transferDriverUiid}/on-the-way`, {
      location: {
        lat: location?.lat,
        lng: location?.lng,
      },
    });
    setStatus("ON_THE_WAY");
    
  } catch (error) {
    console.error("Error updating status to 'ON_THE_WAY'", error);
  }
};

export const arrivedToPickSt = async (transferDriverUiid, setStatus, location) => {
  try {
    await api.post(`/transfers-st/${transferDriverUiid}/arrived-to-pick`, {
      location: {
        lat: location?.lat,
        lng: location?.lng,
      },
    });
    setStatus("ARRIVED");
  } catch (error) {
    console.error("Error updating status to 'ARRIVED'", error);
  }
};

export const startTheRideSt = async (transferDriverUiid, setStatus, location) => {
  try {
    await api.post(`/transfers-st/${transferDriverUiid}/start-the-ride`, {
      location: {
        lat: location?.lat,
        lng: location?.lng,
      },
    });
    setStatus("PICKED_UP");
  } catch (error) {
    console.error("Error updating status to 'PICKED_UP'", error);
  }
};

export const stopTheRideSt = async (
  transferDriverUiid,
  setStatus,
  location
) => {
  try {
    await api.post(`/transfers-st/${transferDriverUiid}/stop-the-ride`, {
      location: {
        lat: location?.lat,
        lng: location?.lng,
      },
    });
    setStatus("DROPPED_OFF");
  } catch (error) {
    console.error("Error stopping the ride", error);
  }
};