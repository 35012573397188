import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

function SignName() {
  const [searchParams] = useSearchParams();
  const firstname = searchParams.get("firstname");
  const lastname = searchParams.get("lastname");
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    let wakeLock = null;

    // Function to enable Wake Lock
    const requestWakeLock = async () => {
      try {
        if ("wakeLock" in navigator) {
          wakeLock = await navigator.wakeLock.request("screen");
          console.log("Wake Lock enabled!");
        } else {
          console.warn("Wake Lock API is not available on this browser.");
        }
      } catch (err) {
        console.error("Failed to enable Wake Lock:", err);
      }
    };

    // Function to release Wake Lock
    const releaseWakeLock = () => {
      if (wakeLock) {
        wakeLock.release().then(() => {
          console.log("Wake Lock released!");
          wakeLock = null;
        });
      }
    };

    // Handle orientation change
    const handleOrientationChange = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    };

    // Reactivate Wake Lock when the app regains focus
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        requestWakeLock();
      } else {
        releaseWakeLock();
      }
    };

    // Add event listeners
    const mediaQuery = window.matchMedia("(orientation: portrait)");
    mediaQuery.addEventListener("change", handleOrientationChange);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    requestWakeLock();

    // Cleanup listeners
    return () => {
      mediaQuery.removeEventListener("change", handleOrientationChange);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      releaseWakeLock();
    };
  }, []);

  return (
    <>
      {isPortrait && (
        <div className="landscape-message" style={landscapeMessageStyle}>
          Please rotate your device for a better experience.
        </div>
      )}
      {!isPortrait && (
        <Container>
          <Row>
            <Col xl={12}>
              <div className="d-flex flex-column vh-100">
                <div className="d-flex flex-grow-1 justify-content-center align-items-center flex-column">
                  <p style={{ color: "white", fontSize: "9vw" }}>{lastname}</p>
                  <p style={{ color: "white", fontSize: "9vw" }}>{firstname}</p>
                </div>
                <div className="d-flex flex-grow-1 justify-content-center align-items-center"></div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

// Style for the orientation message
const landscapeMessageStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  background: "rgba(0, 0, 0, 0.9)",
  color: "white",
  textAlign: "center",
  fontSize: "2rem",
  zIndex: 9999,
};

export default SignName;
