import React from "react";
import { useLocation, Link } from "react-router-dom";

const ErrorPage = () => {
  const location = useLocation();
  const message = location.state?.message || "Page non trouvée.";

  return (
    <div className="text-center p-4">
      <h1 style={{ color: "red" }}>Erreur</h1>
      <p style={{ color: "white" }}>{message}</p>
      <Link to="/">Retour à l'accueil</Link>
    </div>
  );
};

export default ErrorPage;
