// transferFunctions.js
import api from "../../utils/api";
import React, { useState, useCallback } from "react";
import { Button, Row, Modal, Form, Col, Spinner  } from "react-bootstrap";
import debounce from "lodash.debounce"; // Import de lodash.debounce

export const onTheWay = async (transferId, setStatus, location) => {
  try {
    const response = await api.get("/transfers/check-not-finished");
    if (response.data.nb !== 0) {
      alert("You have rides not finished. Please end it");
    } else {
      await api.post(`/transfers/${transferId}/on-the-way`, {
        location: {
          lat: location?.lat,
          lng: location?.lng,
        },
      });
      setStatus("ON_THE_WAY");
    }
  } catch (error) {
    console.error("Error updating status to 'ON_THE_WAY'", error);
  }
};

export const arrivedToPick = async (transferId, setStatus, location) => {
  try {
    await api.post(`/transfers/${transferId}/arrived-to-pick`, {
      location: {
        lat: location?.lat,
        lng: location?.lng,
      },
    });
    setStatus("ARRIVED");
  } catch (error) {
    console.error("Error updating status to 'ARRIVED'", error);
  }
};

export const startTheRide = async (transferId, setStatus, location) => {
  try {
    await api.post(`/transfers/${transferId}/start-the-ride`, {
      location: {
        lat: location?.lat,
        lng: location?.lng,
      },
    });
    setStatus("PICKED_UP");
  } catch (error) {
    console.error("Error updating status to 'PICKED_UP'", error);
  }
};

export const choosePaymentMethod = async (
  transferId,
  method,
  setShowPaymentMethod
) => {
  try {
    await api.post(`/transfers/${transferId}/payment-method`, {
      payment_method: method,
    });
    setShowPaymentMethod(false);
  } catch (error) {
    console.error("Error choosing payment method", error);
  }
};

export const stopTheRide = async (
  transferId,
  payment,
  setShowPaymentMethod,
  setStatus,
  location
) => {
  try {
    if (payment === "PAB") setShowPaymentMethod(true);
    await api.post(`/transfers/${transferId}/stop-the-ride`, {
      location: {
        lat: location?.lat,
        lng: location?.lng,
      },
    });
    setStatus("DROPPED_OFF");
  } catch (error) {
    console.error("Error stopping the ride", error);
  }
};

export const PaymentMethodModal = ({
  transferId,
  show,
  handleClose,
  choosePaymentMethod,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Choose payment method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="p-2">
          <Button
            variant="warning"
            size="lg"
            onClick={() => choosePaymentMethod(transferId, "CB", handleClose)}
          >
            Credit card
          </Button>
        </Row>
        <Row className="p-2">
          <Button
            variant="warning"
            size="lg"
            onClick={() =>
              choosePaymentMethod(transferId, "Especes", handleClose)
            }
          >
            Cash
          </Button>
        </Row>
        <Row className="p-2">
          <Button
            variant="warning"
            size="lg"
            onClick={() => choosePaymentMethod(transferId, "ANCV", handleClose)}
          >
            ANCV
          </Button>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
    </Modal>
  );
};

export const SellRideModal = ({
  show,
  handleClose,
  sellDetails,
  handleSellChange,
  handleSellRide,
  formSellValid,
  isSelling,
}) => {
  const [numberSuggestions, setNumberSuggestions] = useState([]); // Suggestions pour l'autocomplétion
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false); // Indicateur de chargement des suggestions
  
  // Déclarez la fonction debouncée en dehors du composant
  const debouncedFetchNumberSuggestions = debounce(async (query, setNumberSuggestions, setIsLoadingSuggestions) => {
    if (query.length < 2) {
      setNumberSuggestions([]); // Réinitialiser les suggestions si moins de 2 caractères
      return;
    }
  
    setIsLoadingSuggestions(true);
    try {
      const response = await api.get("/search/vehicle-numbers", {
        params: { search: query },
      });
      setNumberSuggestions(response.data); // Mettre à jour les suggestions (tous les champs)
    } catch (error) {
      console.error("Erreur lors du chargement des suggestions :", error);
      setNumberSuggestions([]);
    } finally {
      setIsLoadingSuggestions(false);
    }
  }, 500);
  
  const fetchNumberSuggestions = useCallback(
    (query) => {
      debouncedFetchNumberSuggestions(query, setNumberSuggestions, setIsLoadingSuggestions);
    },
    [setNumberSuggestions, setIsLoadingSuggestions, ] // Les dépendances directes
  );
  

  // Gérer la modification du champ "Number"
  const handleNumberInputChange = (e) => {
    const { value } = e.target;
    handleSellChange({ target: { name: "number", value } }); // Mettre à jour la valeur
    fetchNumberSuggestions(value); // Rechercher des suggestions
  };

  // Gérer la sélection d'une suggestion
  const handleSuggestionSelect = (item) => {

    const nameParts = item.nom.split(" ");
    const firstName = nameParts[0]; // Première partie du nom
    const lastName = nameParts.slice(1).join(" "); // Le reste du nom     

    handleSellChange({ target: { name: "lastName", value: firstName } });
    handleSellChange({ target: { name: "firstName", value: lastName } });
    handleSellChange({ target: { name: "number", value: item.plaque } });
    handleSellChange({ target: { name: "brand", value: item.modele.trim() } });
    handleSellChange({ target: { name: "color", value: item.couleur } });
    handleSellChange({ target: { name: "phone", value: item.tel } });
    handleSellChange({ target: { name: "driverId", value: item.id } });

    setNumberSuggestions([]);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Sell the Ride</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        <Row>
        <Col>
              <Form.Group className="mb-3 position-relative">
                <Form.Label>Number</Form.Label>
                <Form.Control
                  type="text"
                  name="number"
                  value={sellDetails.number}
                  onChange={handleNumberInputChange} // Recherche dynamique
                  autoComplete="off"
                />
                {/* Liste des suggestions */}
                {numberSuggestions.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      zIndex: 10,
                      backgroundColor: "white",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      maxHeight: "150px",
                      overflowY: "auto",
                    }}
                  >
                    {numberSuggestions.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => handleSuggestionSelect(item)} // Sélection d'une suggestion
                        style={{
                          padding: "8px",
                          cursor: "pointer",
                          borderBottom: "1px solid #f1f1f1",
                        }}
                      >
                        {item.plaque} - {item.nom} - {item.modele} ({item.couleur})
                      </div>
                    ))}
                  </div>
                )}
                {/* Spinner pendant le chargement */}
                {isLoadingSuggestions && (
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  >
                    <Spinner animation="border" size="sm" />
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={sellDetails.phone}
                  onChange={handleSellChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Brand</Form.Label>
                <Form.Control
                  type="text"
                  name="brand"
                  value={sellDetails.brand}
                  onChange={handleSellChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Color</Form.Label>
                <Form.Control
                  type="text"
                  name="color"
                  value={sellDetails.color}
                  onChange={handleSellChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={sellDetails.lastName}
                  onChange={handleSellChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={sellDetails.firstName}
                  onChange={handleSellChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              name="price"
              value={sellDetails.price}
              onChange={handleSellChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSellRide}
          disabled={!formSellValid || isSelling}
        >
          {isSelling ? (
            <Spinner animation="border" role="status" variant="success">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <span>Sell the Ride</span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


